import React from 'react';
import { Typography, Box, Divider, Grid } from '@mui/material';
import DisplayField from '../display-field/DisplayField';
import { fromUTCtoTimezone } from 'src/helpers/ConvertToTimezone';

const LeadCardPreview = ({ currentDriver }) => {
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={6}
        sx={{
          width: '50%',
          display: 'flex',
          justifyContent: 'center',
          paddingRight: '8px',
          paddingBottom: '8px',
          borderRight: '1px solid red',
        }}
      >
        <Box
          className="modern-scrollbar"
          sx={{
            width: '100%',
            overflowY: 'auto',
            p: '16px',
            background: 'linear-gradient(to left, #ece9e6, #ffffff)',
            borderRadius: '24px',
          }}
          role="presentation"
          whiteSpace="normal"
        >
          {currentDriver && (
            <Box
              textAlign="center"
              bgcolor="background.paper"
              p={3}
              borderRadius="12px"
              boxShadow={3}
              sx={{ minHeight: '61vh', height: '100%' }}
            >
              <Typography variant="h5" fontWeight="600" mb={2} color="text.primary">
                Card Preview
              </Typography>

              <Divider sx={{ my: 2 }} />

              <Grid
                container
                my={2}
                spacing={0}
                sx={{
                  '& > .MuiGrid-item:nth-of-type(4n - 1), & > .MuiGrid-item:nth-of-type(4n)': {
                    backgroundColor: 'rgba(173, 216, 230, 0.4)',
                  },
                  mb: 0,
                }}
              >
                <DisplayField title="Email" value={currentDriver.lead_meta_email || currentDriver.lead_email || '/'} />
                <DisplayField
                  title="Phone"
                  value={currentDriver.lead_meta_phone_number || currentDriver.lead_phone_number || '/'}
                />
                <DisplayField title="State" value={currentDriver.lead_meta_state || currentDriver.lead_state || '/'} />
                <DisplayField title="Status" value={currentDriver.lead_status} />
                <DisplayField
                  title="Last Status Update"
                  value={
                    currentDriver.lead_status === 'not contacted'
                      ? fromUTCtoTimezone({ inputTime: currentDriver.lead_created_time })
                      : fromUTCtoTimezone({ inputTime: currentDriver.lead_status_modified })
                  }
                />
                {currentDriver.lead_meta_experience && (
                  <DisplayField title="Experience" value={currentDriver.lead_meta_experience} />
                )}
                {currentDriver.lead_meta_lp_experience && (
                  <DisplayField
                    title="LP Experience"
                    value={currentDriver.lead_meta_lp_experience === 'true' ? 'Yes' : 'No'}
                  />
                )}
                {currentDriver.lead_driver_position && (
                  <DisplayField title="Position" value={currentDriver.lead_driver_position} capitalize />
                )}
                {currentDriver.lead_meta_are_you_an_owner_op && (
                  <DisplayField
                    title="Owner Operator"
                    value={currentDriver.lead_meta_are_you_an_owner_op === 'true' ? 'Yes' : 'No'}
                  />
                )}
                {currentDriver.lead_meta_owner_op && (
                  <DisplayField title="Truck Model" value={currentDriver.lead_meta_owner_op} />
                )}
                {currentDriver.lead_meta_trailer_type && (
                  <DisplayField title="Preferred Trailer Type" value={currentDriver.lead_meta_trailer_type} />
                )}
                {(currentDriver.lead_meta_truck_preference_1 || currentDriver.lead_meta_truck_preference_2) && (
                  <DisplayField
                    title="Truck Preferences"
                    value={`${currentDriver.lead_meta_truck_preference_1}, ${currentDriver.lead_meta_truck_preference_2}`}
                  />
                )}
                {currentDriver.lead_meta_driver_type && (
                  <DisplayField title="Driver Type" value={currentDriver.lead_meta_driver_type} />
                )}
                {currentDriver.lead_meta_violation && (
                  <DisplayField title="Violation" value={currentDriver.lead_meta_violation === 'true' ? 'Yes' : 'No'} />
                )}
                {currentDriver.lead_meta_accident && (
                  <DisplayField title="Accident" value={currentDriver.lead_meta_accident === 'true' ? 'Yes' : 'No'} />
                )}
                {currentDriver.lead_meta_drug_test && (
                  <DisplayField title="Drug Test" value={currentDriver.lead_meta_drug_test} />
                )}
                {currentDriver.lead_meta_clean_mvr && (
                  <DisplayField title="MVR" value={currentDriver.lead_meta_clean_mvr} />
                )}
                {currentDriver.lead_meta_clearing_house && (
                  <DisplayField title="Clearing house" value={currentDriver.lead_meta_clearing_house} />
                )}
                {currentDriver.lead_meta_recent_dui_failed_sap && (
                  <DisplayField title="DUI / SAP" value={currentDriver.lead_meta_recent_dui_failed_sap} />
                )}
                {currentDriver.lead_meta_home_time && (
                  <DisplayField title="Home Time" value={currentDriver.lead_meta_home_time} />
                )}
                {currentDriver.lead_meta_start_time && (
                  <DisplayField title="Start Time" value={currentDriver.lead_meta_start_time} />
                )}
                {currentDriver.lead_meta_company_name && (
                  <DisplayField title="Previous Company" value={currentDriver.lead_meta_company_name} />
                )}
                {currentDriver.lead_meta_employment && (
                  <DisplayField
                    title="Previous Employment"
                    value={currentDriver.lead_meta_employment === 'true' ? 'Yes' : 'No'}
                  />
                )}
                {currentDriver.lead_meta_position && (
                  <DisplayField title="Previous Position" value={currentDriver.lead_meta_position} />
                )}
                {currentDriver.lead_meta_why && <DisplayField title="Why" value={currentDriver.lead_meta_why} />}
                {currentDriver.lead_meta_miles && (
                  <DisplayField title="Previous Miles" value={currentDriver.lead_meta_miles} />
                )}

                {currentDriver.lead_meta_pay && (
                  <DisplayField title="Previous Pay" value={currentDriver.lead_meta_pay} />
                )}
                {currentDriver.lead_meta_endorsments && (
                  <DisplayField title="Endorsements" value={currentDriver.lead_meta_endorsments} />
                )}
                {currentDriver.lead_duplicated_phone_number && <DisplayField title="Duplicated Number" value="Yes" />}
                {currentDriver.lead_meta_note && <DisplayField title="Summary" value={currentDriver.lead_meta_note} />}
              </Grid>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          width: '50%',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '8px',
        }}
      >
        <Box
          className="modern-scrollbar"
          sx={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            p: '16px',
            background: 'linear-gradient(to right, #ece9e6, #ffffff)',
            borderRadius: '24px',
          }}
          role="presentation"
          whiteSpace="normal"
        >
          {currentDriver && (
            <Box
              textAlign="center"
              bgcolor="background.paper"
              p={3}
              borderRadius="12px"
              boxShadow={3}
              sx={{ minHeight: '61vh', height: '100%' }}
            >
              <Typography variant="h5" fontWeight="600" mb={2} color="text.primary">
                Application Preview
              </Typography>

              <Divider sx={{ my: 2 }} />

              <Grid
                container
                my={2}
                spacing={0}
                sx={{
                  '& > .MuiGrid-item:nth-of-type(4n - 1), & > .MuiGrid-item:nth-of-type(4n)': {
                    backgroundColor: 'rgba(173, 216, 230, 0.4)',
                  },
                  mb: 0,
                }}
              >
                <DisplayField title="Email" value={currentDriver.lead_email} />
                <DisplayField title="Phone" value={currentDriver.lead_phone_number} />
                {currentDriver.lead_state && <DisplayField title="State" value={currentDriver.lead_state} />}
                <DisplayField
                  title="Created Time"
                  value={fromUTCtoTimezone({ inputTime: currentDriver.lead_created_time })}
                />
                {currentDriver.lead_experience && (
                  <DisplayField title="Experience" value={currentDriver.lead_experience} />
                )}
                {currentDriver.lead_flatbed_experience && (
                  <DisplayField title="Flatbed Experience" value={currentDriver.lead_flatbed_experience} />
                )}
                {currentDriver.lead_reefer_experience && (
                  <DisplayField title="Reefer Experience" value={currentDriver.lead_reefer_experience} />
                )}
                {currentDriver.lead_ZIPCode && (
                  <DisplayField title="ZIP Code" value={currentDriver.lead_ZIPCode} capitalize />
                )}
                {currentDriver.lead_class_a_cdl && (
                  <DisplayField title="CDL Class A" value={currentDriver.lead_class_a_cdl} capitalize />
                )}
                {currentDriver.lead_application && (
                  <DisplayField title="Application" value={currentDriver.lead_application} capitalize />
                )}
                {currentDriver.lead_date_created && (
                  <DisplayField title="Date Created" value={currentDriver.lead_date_created} capitalize />
                )}
                {currentDriver.lead_driven_last_2_years && (
                  <DisplayField title="Driven past 2 years" value={currentDriver.lead_driven_last_2_years} capitalize />
                )}
                {currentDriver.lead_driver_position && (
                  <DisplayField title="Position" value={currentDriver.lead_driver_position} capitalize />
                )}

                {currentDriver.lead_are_you_an_owner_op && (
                  <DisplayField title="Owner Operator" value={currentDriver.lead_are_you_an_owner_op} />
                )}
                {currentDriver.lead_owner_op && (
                  <DisplayField title="Truck Model" value={currentDriver.lead_owner_op} />
                )}
                {currentDriver.lead_kind_of_trailer && (
                  <DisplayField title="Trailer Type" value={currentDriver.lead_kind_of_trailer} />
                )}
                {currentDriver.lead_model_year_truck && (
                  <DisplayField title="Truck Model" value={currentDriver.lead_model_year_truck} />
                )}
                {currentDriver.lead_questions_for_us && (
                  <DisplayField title="Questions" value={currentDriver.lead_questions_for_us} />
                )}
                {currentDriver.lead_recruiter && (
                  <DisplayField title="Recruiter mail" value={currentDriver.lead_recruiter} />
                )}
                {currentDriver.lead_source && <DisplayField title="Source" value={currentDriver.lead_source} />}
                {currentDriver.lead_cdl && <DisplayField title="CDL" value={currentDriver.lead_cdl} />}
                {currentDriver.lead_driver_partner_name && (
                  <DisplayField title="Driver Partner Name" value={currentDriver.lead_driver_partner_name} />
                )}
                {currentDriver.lead_endorsements && (
                  <DisplayField title="Endorsements" value={currentDriver.lead_endorsements} />
                )}
                {currentDriver.lead_pay_structure && (
                  <DisplayField title="Pay Structure" value={currentDriver.lead_pay_structure} />
                )}
                {currentDriver.lead_driver_type && (
                  <DisplayField title="Driver Type" value={currentDriver.lead_driver_type} />
                )}
                {currentDriver.lead_drug_test && (
                  <DisplayField title="Drug Test" value={currentDriver.lead_drug_test} />
                )}
                {currentDriver.lead_clean_mvr && <DisplayField title="MVR" value={currentDriver.lead_clean_mvr} />}
                {currentDriver.lead_clearing_house && (
                  <DisplayField title="Clearing house" value={currentDriver.lead_clearing_house} />
                )}
                {currentDriver.lead_recent_dui_failed_sap && (
                  <DisplayField title="DUI / SAP" value={currentDriver.lead_recent_dui_failed_sap} />
                )}
                {/* Add new fields here */}
              </Grid>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LeadCardPreview;
