import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { calculateDaysAgo, fromUTCtoTimezone } from 'src/helpers/ConvertToTimezone';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { LeadColoredEvent } from './LeadColoredEvent';
import { selectColorOptions } from './ColoredSelect';

const LeadTimeTable = ({ currentDriver, allRecruiters, user }) => {
  const getRecruiterName = (recruiterId) => {
    const recruiter = allRecruiters.find((recruiter) => recruiter.recruiter_id == recruiterId);

    if (recruiter) return recruiter.recruiter_id == user.recruiter_id ? 'You' : recruiter.recruiter_full_name;

    const changedByAdmin = recruiterId == user.company_id;
    if (changedByAdmin) return 'the admin';
    return 'unknown';
  };

  const darkenRGBColor = (rgb, percentage) => {
    const [r, g, b] = rgb.match(/\d+/g).map(Number);

    const darken = (value) => Math.max(0, value - value * (percentage / 100));

    const newR = Math.round(darken(r));
    const newG = Math.round(darken(g));
    const newB = Math.round(darken(b));

    return `rgb(${newR} ${newG} ${newB})`;
  };

  const constructEachEvent = (date, details, index) => {
    if (details.event_name === 'status') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FiberManualRecordIcon
            sx={{
              fontSize: '10px',
              mr: 1,
              mb: '2px',
            }}
          />
          <Typography key={index} my={1}>
            <LeadColoredEvent
              title="Status"
              color={darkenRGBColor(
                Object.entries(selectColorOptions)
                  .filter(([key]) => key === details.content)
                  .map(([, value]) => value)[0],
                20
              )}
            />
            changed to {details.content} by {getRecruiterName(details.recruiter_id)} on
            <Typography component="span" sx={{ fontWeight: '600', mx: 0.5 }}>
              {fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' })}
            </Typography>
            <Typography component="span" sx={{ fontWeight: '400', color: 'grey', ml: 1 }}>
              ({calculateDaysAgo(fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' }))})
            </Typography>
          </Typography>
        </Box>
      );
    } else if (details.event_name === 'first_touch') {
      return (
        <Typography
          sx={{
            ml: 4,
            my: 1.5,
          }}
        >
          Lead arrived on
          <Typography
            component={'span'}
            sx={{
              fontWeight: '600',
              mx: 0.5,
            }}
          >
            {fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' })}
          </Typography>
          for {getRecruiterName(details.recruiter_id ?? currentDriver.lead_recruiter_id)}
          <Typography
            component={'span'}
            sx={{
              fontWeight: '400',
              color: 'grey',
              ml: 1,
            }}
          >
            ({calculateDaysAgo(fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' }))})
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
        </Typography>
      );
    } else if (details.event_name === 'note_created') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FiberManualRecordIcon
            sx={{
              fontSize: '10px',
              mr: 1,
              mb: '2px',
            }}
          />
          <Typography key={index} my={1}>
            <LeadColoredEvent title="Note" color="green" />
            <Typography component="span" sx={{ fontWeight: '600', fontStyle: 'italic' }}>
              "{details.content}"{' '}
            </Typography>{' '}
            added by {getRecruiterName(details.recruiter_id)} on
            <Typography component="span" sx={{ fontWeight: '600', mx: 0.5 }}>
              {fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' })}
            </Typography>
            <Typography component="span" sx={{ fontWeight: '400', color: 'grey', ml: 1 }}>
              ({calculateDaysAgo(fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' }))})
            </Typography>
          </Typography>
        </Box>
      );
    } else if (details.event_name === 'note_deleted') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FiberManualRecordIcon
            sx={{
              fontSize: '10px',
              mr: 1,
              mb: '2px',
            }}
          />
          <Typography key={index} my={1}>
            <LeadColoredEvent title="Note" color="red" />
            <Typography
              component="span"
              sx={{ fontWeight: '600', fontStyle: 'italic', textDecoration: 'line-through' }}
            >
              "{details.content}"
            </Typography>{' '}
            deleted by {getRecruiterName(details.recruiter_id)} on
            <Typography component="span" sx={{ fontWeight: '600', mx: 0.5 }}>
              {fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' })}
            </Typography>
            <Typography component="span" sx={{ fontWeight: '400', color: 'grey', ml: 1 }}>
              ({calculateDaysAgo(fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' }))})
            </Typography>
          </Typography>
        </Box>
      );
    } else if (details.event_name === 'note_updated') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FiberManualRecordIcon
            sx={{
              fontSize: '10px',
              mr: 1,
              mb: '2px',
            }}
          />
          <Typography key={index} my={1}>
            <LeadColoredEvent title="Note" color="blue" />
            <Typography
              component="span"
              sx={{ fontWeight: '600', fontStyle: 'italic', textDecoration: 'line-through' }}
            >
              "{details.content.split('{[(*)]}')[0]}"
            </Typography>{' '}
            updated to{' '}
            <Typography component="span" sx={{ fontWeight: '600', fontStyle: 'italic' }}>
              "{details.content.split('{[(*)]}')[1] && details.content.split('{[(*)]}')[1].trim()}"
            </Typography>{' '}
            by {getRecruiterName(details.recruiter_id)} on
            <Typography component="span" sx={{ fontWeight: '600', mx: 0.5 }}>
              {fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' })}
            </Typography>
            <Typography component="span" sx={{ fontWeight: '400', color: 'grey', ml: 1 }}>
              ({calculateDaysAgo(fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' }))})
            </Typography>
          </Typography>
        </Box>
      );
    } else if (details.event_name === 'lead_duplication_by_phone_number') {
      return (
        <>
          <Typography
            key={index}
            sx={{
              ml: 4,
              my: 1.5,
            }}
          >
            Lead arrived with a
            <LeadColoredEvent title="duplicated phone number" color="darkOrange" />
            for {getRecruiterName(details.recruiter_id)} on
            <Typography component="span" sx={{ fontWeight: '600', mx: 0.5 }}>
              {fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' })}
            </Typography>
            <Typography component="span" sx={{ fontWeight: '400', color: 'grey', ml: 1, textDecoration: 'unset' }}>
              ({calculateDaysAgo(fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' }))})
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
          </Typography>
        </>
      );
    } else if (details.event_name === 'lead_recycled') {
      return (
        <Typography
          key={index}
          sx={{
            ml: 4,
            my: 1.5,
          }}
        >
          This lead has not responded with the previous recruiter and been passed on to{' '}
          {getRecruiterName(details.recruiter_id)} on
          <Typography component="span" sx={{ fontWeight: '600', mx: 0.5 }}>
            {fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' })}
          </Typography>
          <Typography component="span" sx={{ fontWeight: '400', color: 'grey', ml: 1 }}>
            ({calculateDaysAgo(fromUTCtoTimezone({ inputTime: date, inputFormat: 'YYYY-MM-DD hh:mm:ss' }))})
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
        </Typography>
      );
    }
  };

  return (
    <>
      <Box
        textAlign="center"
        bgcolor="background.paper"
        p={1}
        borderRadius="12px"
        boxShadow={3}
        sx={{
          mb: '20px',
          minHeight: '62vh',
        }}
      >
        <Typography variant="h5" fontWeight="600" mb={2} color="text.primary">
          Lead Timeline
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Box textAlign="left">
          {currentDriver.lead_event_log &&
            Object.entries(currentDriver.lead_event_log).map(([date, details], index) => {
              return <React.Fragment key={date}>{constructEachEvent(date, details, index)}</React.Fragment>;
            })}
        </Box>
        {/* )} */}
      </Box>
    </>
  );
};

export default LeadTimeTable;
